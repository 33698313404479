@import url("./../../../assets/css-generated/variables.css");

.app {
    margin-top: 25px;
    width: 100%;
    min-height: calc(100vh - 150px);
    padding: 10px 20px;
    display: block;
}
html {
    background: var(--light-background-background-2);
    transition: .3s linear all;
}
body{
    font-family: 'Source Sans 3' !important;
}
html.light {
    transition: .3s linear all;
}

html.dark  {
    transition: .3s linear all;
} 
/*  ################
    Dark MODE
    ################ */
/* .light li.menu-item a > div svg path{
    fill: red;
}
.dark li.menu-item a > div svg path{
    fill: var(--light-text-text-3);
}

html.light {
    background: #f0f2f5;
    transition: .3s linear all;
}

html.dark  {
    background: #333333;
    transition: .3s linear all;
} */
/*  ################
    FORMULARIOS
    ################ */
/* .MuiInputBase-root.MuiOutlinedInput-root {
    border-radius: 10px !important;
} */
/* .MuiInputBase-root {
    margin-bottom: 15px !important;
} */
.MuiFormControl-root {
    width: 100%;
    margin-bottom: 15px !important;
}

/*  ################
    Buttons
    ################ */

.btn.btn-secondary-green{
    background: var(--light-secondary-green);
    color: var(--light-text-text-6);
    font-weight: bold;
}

/*  ################
    Dropdowns
    ################ */
.btn.btn-primary-yellow-1{
    background: var(--light-primary-yellow-1);
}

.actions-link{
    color: var(--light-text-text-2);
    font-weight: 400;
    font-size: 12px;
    margin: 0px;
}

/*  ################
    ASIDE
    ################ */
.aside{
    min-height: 100vh;
    display: block;
    float: left;
    max-width: 250px;
    position: fixed;
    background: var(--light-primary-blue-2);
    left: 0px;
    transition: all .3s linear !important;
}
.aside.hide {
    width: 70px;
    z-index: 1;
}
.aside.show {
    width: 250px;
}
.aside.hide li.menu-item:after {
    width: 75%;
}
.aside.hide li.menu-item.collapsable > a, .aside.hide li.menu-item.no-collapsable > a  {
    position: relative;
    width: 100%;
    display: flex;
    min-height: 43px;
    justify-content: center;
}
.aside.hide .menu-item span.material-symbols-outlined {
    font-weight: 100;
    width: 100%;
    font-size: 30px;
    text-align: center;
}
.aside.hide span.menu-item-name {
    display: none;
}
.aside.hide li.menu-item:hover .menu-item-name{
    display: block;
}
.aside.hide:hover{
    width: 250px;
    z-index: 10;
}
.aside.hide:hover span.menu-item-name {
    display: block;
}

.bold-container {
    display: block;
    float: left;
    margin-left: 250px;
    min-height: 96vh;
    width: calc(100% - 250px);
    position: relative;
    transition: all .3s linear;
}
.bold-container.full {
    margin-left: 70px;
    width: calc(100% - 70px);
    transition: all .3s linear;
}

.brand-logo {
    position: sticky;
    top: 0px;
    width: 100%;
    display: block;
    z-index: 1;
}

/*################
    POLICIES
################## */
.text-box-policies {
    display: block;
    background: #fafafa;
    min-height: 300px;
    max-height: 360px;    
    border-radius: 12px;
    padding: 0px;
    border: 1px solid #e9e9e9;
    overflow: hidden;
}
.text-box-policies div{
    overflow-y: auto;
    min-height: 300px;
    max-height: 360px;
    padding: 15px 10px;
}
/*################
    MENU
################## */
/* ul#menu-list {
    overflow: auto;
    display: block;
    height: 83vh;
} */
.shadow-aside {
    background: #76767645;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
}
.shadow-aside .menu-btn-toggle{
    display: block;
}
ul#menu-list {
    overflow: auto;
    display: block;
    height: 90vh;
    margin-top: 44px;
}
/* ASIDE ACCORDION */
li.menu-item a{
    height: 54px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #cdcdcd;
    font-weight: 100;
    line-height: 30px;
    padding: 4px 5px 4px 20px;
    margin: 2px 0px;
}
li.menu-item.collapsable>a:after {
    content: "";
    width: 10px;
    height: 10px;
    position: absolute;
    right: 8px;
    top: 25px;
    border-bottom: 2px solid #bababa;
    border-right: 2px solid #bababa;
    transform: rotate(225deg);
}
li.menu-item.collapsable>a.collapsed:after {
    content: "";
    width: 10px;
    height: 10px;
    position: absolute;
    right: 8px;
    top: 20px;
    border-bottom: 2px solid #bababa;
    border-right: 2px solid #bababa;
    transform: rotate(45deg);
}


ul#menu-list > li.menu-item:after {
    content: "";
    display: block;
    height: 1px;
    background: #acacac2b;
    width: 95%;
    margin: auto;
}

li.menu-item a > div{
    float: left;
    padding-right: 10px;
}

li.menu-item a > div svg path{
    fill: var(--light-text-text-3);
}
li.menu-item svg {
    width: auto !important;
    height: 24px !important;
}

li.menu-item a:hover > div svg path{
    fill: var(--light-primary-blue-1);
}

li.menu-item>a:hover {
    background: white;
    color: #264c99;
    box-shadow: inset 4px 0px 0px 0px #F0B669;
}


.container-menu{
    background: white;
    padding: 0px 10px;
}

/*  ################
    SECTION PAGE
    ################ */
.title-page{
    margin: 0px;
}
/*  ################
    SECTION PAGE CHILD MENU
    ################ */
nav.child-menu a.active {
    border-top: solid 4px #264c99;
    background: #f7f8fa;
    color: #264c99;
}

nav.child-menu a {
    padding: 10px;
    display: inline-block;
    text-decoration: none;
    color: black;
    font-weight: 400;
    border-radius: 4px 4px 0px 0px;
    box-sizing: border-box;
}
nav.child-menu {
    background: white;
    margin: 10px 0px 30px;
    border-radius: 4px;
}
/*################
    Filters
################## */
.filters{
    margin-bottom: 20px;
}
/*################
    TABLE
################## */
img.bold-image-table {
    width: 45px;
    max-height: 45px;
    min-height: 45px;
    border-radius: 50%;
    border: none;
    margin: 5px 0px;
}

div.initial_avatar{
    width: 45px;
    max-height: 45px;
    min-height: 45px;
    border-radius: 50%;
    border: none;
    margin: 5px 0px;
    background-color: #3165CC; /* Cambia el color de fondo según tus preferencias */
    color: #fff; /* Color del texto */
    font-size: 20px; 
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #007bff; 
    display: flex;
    justify-content: center;
    align-items: center;
   
    font-weight: bold;
    color: #fff;  */
}

.bold-header-table h3 {
    color: #000000;
    font-weight: 500;
    margin-left: 32px;
}

.bold-header-table {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}
.rdt_Table {
    border: 1px solid var(--light-elements-elements-5);
    border-radius: 8px;
    overflow: hidden;
}
.rdt_TableHead, .rdt_TableHeadRow{
    background-color: var(--light-background-background-1) !important;
}
.rdt_TableBody > div{
    border:0px;
}
.rdt_TableBody > div:nth-child(odd) {
    background: var(--light-background-background-2);
}
nav.rdt_Pagination {
    background: transparent;
    border:0px;
}

.material-symbols-outlined.active {
    color: #264c99;
}

.material-symbols-outlined.inactive{
    color: #ff9b15;
}

/*CLASE CUSTOMER TYPE*/
.clientTypeNew{
    font-size: 10;
}

.dataTable{
    padding-right: 2rem;
    padding-left: 2rem;
    padding-bottom: 2rem;
    background-color: #fff;
    border-radius: 5px;
}

.select {
    color: aqua;
}

@media(max-width: 768px){
    .bold-container {
        margin-left: 0px;
        width: 100%;
        transition: all .3s linear;
    }
    .aside.hide {
        left: -265px;
        transition: all 0.3s linear;
    }

    .bold-container.full{
        margin-left: 0px;
        width: 100%;
    }
}