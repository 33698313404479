
/* Form products */
/* .app {
    margin-top: 40px;
    width: 100%;
    min-height: calc(100vh - 150px);
} */
.card-option {
    display: block;
    text-align: center;
    border: 1px solid #dbdbdb;
    border-radius: 8px;
    overflow: hidden;
    margin: 10px 5px;
    box-shadow: 2px 2px 12px 0px #c4c4c4;
}
.card-option.selected {
    border: 2px solid #1876d2;
    transform: scale(1.1);
}

.card-option-img-content {
    display: flex;
    padding: 0px;
    overflow: hidden;
    width: 100%;
    align-items: center;
    justify-content: center;
}
img.card-option-img {
    display: block;
    width: 90%;
}
.card-option-content {
    display: flex;
    min-height: 45px;
    padding: 5px;
    align-items: center;
    justify-content: center;
    background: #ededed;
    font-weight: 600;
}

/* dropdown */
.dropdown-item {
    margin: 3px;
    padding: 4px 10px;
    border-bottom: 1px solid #f2f2f2;
}
.dropdown-item-icon-left span {
    padding-left: 10px;
}
/* main header */
.btn-action-dash span.material-symbols-outlined {
    position: relative;
    top: 3px;
    font-size: 22px;
}
.actions-dashboard {
    display: flex;
    flex-wrap: nowrap;
}
span.location-label {
    font-size: 13px;
    color: #7d7d7d;
    line-height: 13px;
}

/* table */
.bold-table-note {
    max-width: 920px;
    padding: 0px 20px;
    margin: 10px 0px;
}

/* btn table actions*/
.btn-table-actions.wx2 {
    width: 52px;
    position: relative;
}
span.xsmall {
    font-size: 8px !important;
    position: absolute;
    top: 20px;
    left: 0px;
    right: 0;
}


/* card products selected in form */
.card-option-selected {
    border: 2px solid #ef0000;
}


.table-header {
    font-weight: bold !important;
}

button.btn-filter-date {
    border: 2px solid #cccccc;
    padding: 1px 8px;
    margin-right: 5px;
    border-radius: 5px;
    font-size: 14px;
}
button.btn-filter-date.btn-active {
    background: #254c99;
    color: #FFFFFF;
    border: 2px solid #012875;
}