/* Estilos Definitivos del Framework bold */

.no-view {
    display: none !important;
}
.absolute{
    position: absolute;
}
.np-row{
    margin-right: 0px !important;
    margin-left: 0px !important;
}
.ph-20 {
    padding-left: 20px;
    padding-right: 20px;
}

/* Buttons */
.btn-primary{
    background: rgba(130, 70, 202, 0.8);
    /* background: rgba(238, 168, 55, 0.8); */
    color: #000000;
    border:0px;
    font-weight: 600;
}
.btn-light{
    background: transparent;
    border: 1px solid #c6c5c5 !important;
}

.btn-primary:hover, .btn-light:hover{
    /* background:  rgba(255, 175, 46, 0.6); */
    background: rgba(156, 87, 240, 0.8);
    color: #000000;
}


/* Text */
.regular {
    font-weight: 600;
}

.light {
    font-weight: 300;
}
.small-text{
    font-size: 13px;
}

.text-green {
    color: rgb(5, 171, 11);
}

.text-orange {
    color: rgb(219, 129, 38);
}

.text-red {
    color: rgb(192, 7, 7);
}

.text-gray {
    color: rgb(159, 159, 159) !important;
}
.bold-text-field-material textarea{
    font-size: 13px !important;
}

/* lenguajes drop down */
.language-login {
    position: fixed;
    right: 20px;
    top: 10px;
}
/* Formularios */
.text-input-error{
    color: #E02F51;
    font-size: 12px;
}

/* ################################################################################################ */
/* -------------------------------Arriba esta todo muy definido :) abajo puede eliminarse futuramente--------------------------------------- */




/* .menu-btn-toggle {
    display: none;
    position: absolute;
    right: 15px;
    top: 40px;
    color: white;
    background: #282828;
    width: 36px;
    height: 36px;
    padding-top: 6px;
    padding-left: 4px;
    border-radius: 50%;
    text-shadow: 2px 1px 4px #b9b9b9;
} */
.menu-btn-toggle {
    display: none;
    position: absolute;
    right: 15px;
    top: 40px;
    font-size: 30px;
    color: #c7c7c7;
    background: #282828;
    width: 36px;
    font-weight: 200;
    height: 36px;
    line-height: 34px;
    text-align: center;
    border-radius: 50%;
    text-shadow: 2px 1px 4px #838383;
}

span.MuiSkeleton-root {
    margin-bottom: 10px;
}

.aside ul {
    list-style: none;
    padding: 0px;
}



.loader {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #dedede96;
    z-index: 1;
}

.bold-container.responsive {
    margin-left: 75px;
    width: calc(100% - 80px);
}

.logo {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.106);
}

/* ASIDE */



li.menu-item>a:hover {
    background: #565656;
}

li.menu-item span.material-symbols-outlined {
    line-height: 22px;
    font-size: 20px;
    position: relative;
    top: 3px;
    padding-right: 10px;
}

/* ASIDE ACCORDION */


/* MENU */
.title-dashboard {
    padding-left: 6px;
}

/* INPUTS */
.input-icon {
    position: relative;
    width: 100%;
}
/* input search */
.input-icon.input-search .clear{
    color: #df0606;
    right: 30px;
    font-size: 16px;
    top: 10px;
    cursor: pointer;
}

.input-icon input {
    width: 100%;
    margin-right: 10px;
    border: 1px solid #cccccc;
    border-radius: 8px;
    height: 36px;
    padding-right: 30px;
    padding-left: 10px;
    font-size: 14px;
}
.input-icon span {
    position: absolute;
    top: 6px;
    right: 5px;
    color: #a5a5a5;
    text-shadow: 2px 1px 2px #e0dddd;
}

/* FOOTER */
.footer-branch {
    font-size: 15px;
    margin: 20px 15px 0px;
}

ul.important-links {
    display: flex;
    list-style: none;
    margin-bottom: 5px;
}

ul.important-links li {
    padding: 0px 12px;
}

ul.important-links a {
    text-decoration: none;
}

.company-branch-link {
    font-weight: 700;
    text-decoration: none;
    color: #272751;
}

/* TABLE */
.container-table header {
    display: none;
}
.bold-header-table .section-events {
    width: 50%;
    display: flex;
    justify-content: flex-end;
}

.section-events .input-icon {
    width: 70%;
    margin-right: 15px;
}

button.btn-table-actions {
    background: transparent;
    border: 0px;
    color: #949494;
    text-shadow: 2px 1px 2px #e0dddd;
}

button.btn-table-actions span {
    font-size: 20px;
}

.rdt_TableCol {
    color: rgb(123, 128, 154);
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 700;
}

.rdt_TableCell {
    color: rgb(91 91 91);
    font-weight: 300;
}

dt {
    font-weight: 600;
    color: #535566;
    font-size: 15px;
}
dd {
    margin-bottom: 0.5rem;
    margin-left: 0;
    font-weight: 200;
    color: #9a98b3;
    word-wrap: break-word;
}

/* BUTTOMS */
.btn-action-dash{
    border:0px;
    display: inline-block;
    padding: 4px;
    margin: 0px 2px;
    background: transparent;
}
button.dropdown-item.selected {
    font-weight: 700;
}
.bold-btn {
    border: 0px;
    padding: 6px 12px;
    font-size: 16px;
    line-height: 22px;
    vertical-align: middle;
    display: inline-block;
    background: transparent;
    margin: 5px 10px;
}

button.bold-btn-dark {
    color: white;
    background: linear-gradient(0deg, black, #000000c4);
    border-radius: 8px;
}

button.bold-btn-light {
    color: #514f4f;
    background: linear-gradient(195deg, rgb(235, 239, 244), rgb(206, 212, 218));
    border-radius: 8px;
}

/* Forms */

.section-image-view {
    text-align: center;
    width: 150px;
    max-width: 150px;
    max-height: 150px;
    min-height: 125px;
    background-size: cover;
    background-position: center;
    border-radius: 8px;
    border: 2px solid grey;
}
.bold-container-content > .row {
    max-width: 960px;
    margin-left: auto !important;
    margin-right: auto !important;
}

.section-forms {
    background: white;
    padding: 30px 20px;
    border-radius: 15px;
    margin: 10px;
    box-shadow: rgb(0 0 0 / 10%) 0rem 0.25rem 0.375rem -0.0625rem, rgb(0 0 0 / 6%) 0rem 0.125rem 0.25rem -0.0625rem;
}

.title-section-forms {
    display: block;
    text-align: center;
    color: rgb(52, 71, 103);
    font-size: 28px;
    font-weight: 600;
    margin: 0px 0px 40px;
}

.form-title {
    font-size: 20px;
    margin: 30px 0px 8px;
    color: #344767;
    font-weight: 400;
    padding: 0px 20px;
}
span.divisor {
    width: 80%;
    margin: 10px auto;
    display: block;
    height: 1px;
    background: #e7e7e7;
}

/* radio group */
.MuiFormGroup-root {
    display: block !important;
    padding: 10px 15px;
}
.MuiFormGroup-root label {
    padding-right: 15px;
}
.MuiFormGroup-root label span:nth-child(2) {
    font-size: 15px;
    font-weight: 300;
}

/* checkbox */
label.MuiFormControlLabel-root span:nth-child(2) {
    font-weight: 300;
    color: #636363;
}





/* Widgets */
.widget {
    background: #fff;
    border-radius: 20px;
    padding: 10px 15px 20px;
    margin: 0px 15px 40px;
    position: relative;

}

.widget hr {
    clear: both;
    color: rgb(174, 174, 174);
}

.icon-widget-1 {
    background: linear-gradient(0deg, rgb(16 66 121), rgb(34 134 244));
}

.icon-widget-2 {
    background: linear-gradient(0deg, rgb(14 117 18), rgb(40 227 125));
}

.icon-widget-3 {
    background: linear-gradient(0deg, rgb(160 6 6), rgb(255 36 90));
}

.icon-widget-4 {
    background: linear-gradient(0deg, rgb(73, 73, 171), rgb(116, 116, 222));
}

.icon-widget {
    width: 60px;
    height: 60px;
    text-align: center;
    position: absolute;
    top: -16px;
    left: 25px;
    border-radius: 8px;
}

.icon-widget span {
    color: white;
    width: 100%;
    text-align: center;
    line-height: 60px;
    font-size: 30px;
}

.title-widget {
    display: block;
    float: right;
    font-weight: 200;
    color: rgb(154, 154, 154);
    padding: 0px 10px 10px;
}

.title-widget-complement {
    color: rgb(0, 0, 0);
    font-weight: 600;
}

.compare-widget {
    font-weight: 300;
}


/* MUI library */
/* borde inferior inputs */
.css-1dbuez4-MuiInputBase-root-MuiInput-root-MuiSelect-root:before,
.css-whebh7-MuiInputBase-root-MuiInput-root:before,
.css-1np443e-MuiInputBase-root-MuiInput-root:before {
    border-bottom: 1px solid #c1c0c06b !important;
}

.css-1c2i806-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    color: #1870c8 !important;
    font-weight: 500 !important;
}

.MuiPaper-root {
    border-radius: 10px !important;
    margin-top: 2px;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
    margin: 0px 6px !important;
    border-radius: 7px !important;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
    background-color: rgb(240 244 249) !important;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:hover {
    border-radius: 7px;
    margin: 0px 6px;
    background-color: rgb(29 130 230 / 27%) !important;
}

.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl {
    color: rgb(123, 128, 154);
    font-weight: 200;
}

.MuiFormControl-root.MuiTextField-root label {
    font-weight: 200;
}

/*USERS FORM*/
.circle-name {
    background: #2b2b2f;
    color: white;
    text-align: center;
    height: 3em;
    width: 3em;
    border-radius: 50%;
    font-size: 3em;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
}
.form-username {
    display: flex;
    align-items: center;
    flex-direction: column;
}
.form-username label {
    margin: 20px 0;
    color: #61616a;
}
