/**
 * Do not edit directly
 * Generated on Tue, 20 Jun 2023 09:53:06 GMT
 */

:root {
  --light-primary-yellow-1: #F0B669;
  --light-primary-yellow-2: #E8901B;
  --light-primary-yellow-3: #FFC67A;
  --light-primary-yellow-4: #FFD8A3;
  --light-primary-yellow-5: #FFEBD1;
  --light-primary-blue-1: #3165CC;
  --light-primary-blue-2: #254C99;
  --light-primary-blue-3: #7D9EE0;
  --light-primary-blue-4: #B1C5EC;
  --light-primary-blue-5: #E5ECF9;
  --light-secondary-blue-gray: #263E5F;
  --light-secondary-blue-king: #3C89E0;
  --light-secondary-orange: #FDA93D;
  --light-secondary-hard-orange: #FB844E;
  --light-secondary-red: #E02F51;
  --light-secondary-green: #7AC736;
  --light-secondary-black: #000000;
  --light-status-success: #02B15A;
  --light-status-success-2: #D4F2E3;
  --light-status-warning: #967002;
  --light-status-info: #0252B0;
  --light-status-warning-2: #F2EBD5;
  --light-status-info-2: #D1D9E3;
  --light-status-error: #FF0000;
  --light-status-error-2: #F8D2D8;
  --light-status-to-begin: #000D1D;
  --light-status-to-begin-2: #DADADA;
  --light-status-alternative: #4500B0;
  --light-status-alternative-2: #D8D1E3;
  --light-text-tex-1: #000000;
  --light-text-text-2: #37465a;
  --light-text-text-3: #ffffff;
  --light-text-text-4: #f5f7fa;
  --light-text-text-5: #ffffff;
  --light-text-text-6: #000000;
  --light-text-text-7: #A4A4A4;
  --light-text-text-8: #a4a4a4;
  --light-text-text-9: #a4a4a4;
  --light-neutral-gray-1: #666666;
  --light-neutral-gray-2: #a4a4a4;
  --light-neutral-gray-3: #d8d8d8;
  --light-neutral-gray-4: #eeeeee;
  --light-neutral-gray-5: #f6f6f6;
  --light-neutral-white: #ffffff;
  --light-background-background-1: #ffffff;
  --light-background-background-2: #F7F8FA;
  --light-font-families-source-sans-pro: Source Sans Pro;
  --light-line-heights-0: 18;
  --light-line-heights-1: 70;
  --light-line-heights-2: AUTO;
  --light-line-heights-3: 24;
  --light-line-heights-4: 22;
  --light-font-weights-source-sans-pro-0: Regular;
  --light-font-weights-source-sans-pro-3: Italic;
  --light-font-weights-source-sans-pro-1: Bold;
  --light-font-weights-source-sans-pro-2: SemiBold;
  --light-font-size-0: 10;
  --light-font-size-1: 12;
  --light-font-size-2: 14;
  --light-font-size-3: 16;
  --light-font-size-4: 18;
  --light-font-size-5: 24;
  --light-font-size-6: 28;
  --light-font-size-7: 30;
  --light-font-size-8: 32;
  --light-font-size-9: 34;
  --light-font-size-10: 36;
  --light-font-size-11: 46;
  --light-font-size-12: 52;
  --light-font-size-13: 64;
  --light-font-size-14: 8;
  --light-letter-spacing-0: 0;
  --light-paragraph-spacing-0: 0;
  --light-text-case-none: none;
  --light-text-decoration-none: none;
  --light-5-px: 5 px;
  --light-10-px: 10 px;
  --light-15-px: 15 px;
  --light-20-px: 20 px;
  --light-25-px: 25 px;
  --light-30-px: 30 px;
  --light-35-px: 35 px;
  --light-40-px: 40 px;
  --light-45-px: 45 px;
  --light-50-px: 50 px;
  --light-55-px: 55 px;
  --light-60-px: 60 px;
  --light-65-px: 65 px;
  --light-70-px: 70 px;
  --light-75-px: 75 px;
  --light-80-px: 80 px;
  --light-85-px: 85 px;
  --light-90-px: 90 px;
  --light-95-px: 95 px;
  --light-100-px: 100 px;
  --light-soft: [object Object];
  --light-soft-down: [object Object];
  --light-hard: [object Object];
  --light-elements-elements-1: #d8d8d8;
  --light-elements-elements-2: #eeeeee;
  --light-elements-elements-3: #d8d8d8;
  --light-elements-elements-4: #666666;
  --light-elements-elements-5: #D5DAE5;
  --light-elements-elements-6: #F2F6FF;
  --light-elements-elements-7: #d8d8d8;
  --light-elements-elements-8: #384c75;
  --light-elements-elements-9: #fdf4f3;
  --light-elements-elements-10: #000000;
  --light-elements-elements-11: #000000;
  --light-elements-elements-12: #000000;
  --light-elements-elements-13: #000000;
  --light-elements-elements-14: #FFEBD1;
  --light-elements-elements-15: #D5DAE5;
  --light-elements-border-panels-1: #D8D8D8;
  --light-elements-border-panels-2: #4d689e;
  --light-opacity-opacity-1: #000000b3;
  --light-services-violet-violet-5: #f3aece;
  --light-services-violet-violet-3: #ed6b9d;
  --light-services-violet-violet-1: #ce3496;
  --light-services-violet-violet-4: #ec64b7;
  --light-services-violet-violent-2: #e170a9;
  --light-services-purple-purple-5: #dfc0e9;
  --light-services-purple-purple-3: #b29cf8;
  --light-services-purple-purple-1: #8e81af;
  --light-services-purple-purple-4: #c4a9e6;
  --light-services-purple-purple-2: #b393c3;
  --light-services-orange-orange-4: #ee815e;
  --light-services-orange-orange-2: #f08a34;
  --light-services-orange-orange-5: #ffbb9c;
  --light-services-orange-orange-3: #f3a247;
  --light-services-orange-orange-1: #ea5b29;
  --light-services-blue-blue-5: #92deec;
  --light-services-blue-blue-4: #b2dafa;
  --light-services-blue-blue-3: #8abefa;
  --light-services-blue-blue-2: #4ca6ca;
  --light-services-blue-blue-1: #759bcb;
  --light-services-yellow-yellow-5: #f9dd8f;
  --light-services-yellow-yellow-4: #f1d072;
  --light-services-yellow-yellow-3: #fad95d;
  --light-services-yellow-yellow-2: #e2b14b;
  --light-services-yellow-yellow-1: #888034;
  --light-services-green-green-5: #7cb688;
  --light-services-green-green-4: #d2e79a;
  --light-services-green-green-3: #a6d5ae;
  --light-services-green-green-2: #84c9b0;
  --light-services-green-green-1: #84a679;
  --light-gradient-gradient-blue: linear-gradient(180deg, #3165cc8f 0%, #3165cc14 100%);
  --dark-primary-yellow-1: #F5BD74;
  --dark-primary-yellow-2: #EBA142;
  --dark-primary-yellow-3: #FFC67A;
  --dark-primary-yellow-4: #FFD8A3;
  --dark-primary-yellow-5: #FFEBD1;
  --dark-primary-blue-1: #7399e5;
  --dark-primary-blue-2: #5080de;
  --dark-primary-blue-3: #b9ccf2;
  --dark-primary-blue-4: #dce6f8;
  --dark-primary-blue-5: #ecf0f8;
  --dark-secondary-blue-gray: #D5DAE5;
  --dark-secondary-orange: #FFC67C;
  --dark-secondary-green: #B2ED7D;
  --dark-secondary-blue-king: #94BEED;
  --dark-secondary-red: #E5647D;
  --dark-secondary-hard-orange: #FF8C59;
  --dark-secondary-black: #ffffff;
  --dark-status-success: #03CC68;
  --dark-status-success-2: #15291F;
  --dark-status-warning: #CC9803;
  --dark-status-warning-2: #4D4326;
  --dark-status-info: #2E82E5;
  --dark-status-info-2: #213349;
  --dark-status-error: #FF0000;
  --dark-status-error-2: #2D1317;
  --dark-status-to-begin: #747474;
  --dark-status-to-begin-2: #242831;
  --dark-status-alternative: #5000CC;
  --dark-status-alternative-2: #2C233A;
  --dark-neutral-gray-1: #f6f6f6;
  --dark-neutral-gray-2: #eeeeee;
  --dark-neutral-gray-3: #d8d8d8;
  --dark-neutral-gray-4: #a4a4a4;
  --dark-neutral-gray-5: #666666;
  --dark-neutral-white: #000000;
  --dark-text-tex-1: #ffffff;
  --dark-text-text-2: #d5dce5;
  --dark-text-text-3: #000000;
  --dark-text-text-4: #f5f7fa;
  --dark-text-text-5: #ffffff;
  --dark-text-text-6: #000000;
  --dark-text-text-7: #3E3F40;
  --dark-text-text-8: #242831;
  --dark-text-text-9: #404040;
  --dark-background-background-1: #171717;
  --dark-background-background-2: #1C1C1C;
  --dark-opacity-opacity-1: #000000b3;
  --dark-elements-elements-1: #404040;
  --dark-elements-elements-2: #282828;
  --dark-elements-elements-3: #eeeeee;
  --dark-elements-elements-4: #384c75;
  --dark-elements-elements-5: #4d689e;
  --dark-elements-elements-6: #293959;
  --dark-elements-elements-7: #0B172F;
  --dark-elements-elements-8: #f0f5ff;
  --dark-elements-elements-10: #b4cdfe;
  --dark-elements-elements-9: #431a22;
  --dark-elements-elements-11: #000000;
  --dark-elements-elements-12: #132039;
  --dark-elements-elements-13: #132639;
  --dark-elements-elements-14: #4D4326;
  --dark-elements-elements-15: #293959;
  --dark-elements-border-panels-1: #666666;
  --dark-elements-border-panels-2: #7ea8fd;
}
