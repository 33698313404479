@import url("./../../../assets/css-generated/variables.css");

html.dark {
    background: var(--dark-background-background-2);
    transition: .3s linear all;
}
body{
    font-family: 'Source Sans 3' !important;
}
/*  ################
    Dark MODE
    ################ */
/* .light li.menu-item a > div svg path{
    fill: red;
}
.dark .dark li.menu-item a > div svg path{
    fill: var(--dark-text-text-3);
}
.dark 
html.light {
    background: #f0f2f5;
    transition: .3s linear all;
}
.dark 
html.dark  {
    background: #333333;
    transition: .3s linear all;
} */

/*  ################
    ASIDE
    ################ */
.dark  .aside{
    min-height: 100vh;
    display: block;
    float: left;
    max-width: 250px;
    position: fixed;
    background: var(--dark-primary-blue-2);
    left: 0px;
    transition: all .3s linear !important;
}
.dark .aside.hide {
    width: 100px;
}
.dark .aside.show {
    width: 250px;
}
.dark .bold-container {
    display: block;
    float: left;
    margin-left: 250px;
    min-height: 96vh;
    width: calc(100% - 250px);
    position: relative;
    transition: all .3s linear;
}
.dark .bold-container.full {
    margin-left: 100px;
    width: calc(100% - 100px);
    transition: all .3s linear;
}
.dark .brand-logo {
    position: sticky;
    top: 0px;
    width: 100%;
    display: block;
    z-index: 1;
}
/*################
    MENU
################## */
.dark ul#menu-list {
    overflow: auto;
    display: block;
    height: 90vh;
    margin-top: 44px;
}
/* ASIDE ACCORDION */
.dark li.menu-item a{
    height: 54px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #cdcdcd;
    font-weight: 100;
    line-height: 30px;
    padding: 4px 5px 4px 10px;
    margin: 2px 0px;
}
.dark li.menu-item.collapsable>a:after {
    content: "";
    width: 10px;
    height: 10px;
    position: absolute;
    right: 8px;
    top: 25px;
    border-bottom: 2px solid #bababa;
    border-right: 2px solid #bababa;
    transform: rotate(225deg);
}
.dark li.menu-item.collapsable>a.collapsed:after {
    content: "";
    width: 10px;
    height: 10px;
    position: absolute;
    right: 8px;
    top: 20px;
    border-bottom: 2px solid #bababa;
    border-right: 2px solid #bababa;
    transform: rotate(45deg);
}
.dark ul#menu-list > li.menu-item:after {
    content: "";
    display: block;
    height: 1px;
    background: #acacac2b;
    width: 95%;
    margin: auto;
}
.dark .aside.hide li.menu-item:after {
    width: 75%;
}
.dark li.menu-item a > div{
    float: left;
    padding-right: 10px;
}
.dark li.menu-item a > div svg path{
    fill: var(--dark-text-text-3);
}
.dark li.menu-item svg {
    width: auto !important;
    height: 24px !important;
}
.dark li.menu-item a:hover > div svg path{
    fill: var(--dark-primary-blue-1);
}
/* .aside.hide li.menu-item > a { */
.dark .aside.hide li.menu-item.collapsable > a, .aside.hide li.menu-item.no-collapsable > a  {
    position: relative;
    width: 100%;
    display: flex;
    min-height: 43px;
    justify-content: center;
}
.dark .aside.hide .menu-item span.material-symbols-outlined {
    font-weight: 100;
    width: 100%;
    font-size: 30px;
    text-align: center;
}
.dark .aside.hide span.menu-item-name {
    display: none;
}
/* .aside.hide li.menu-item.collapsable>a.collapsed:after{
    display: none;
} */
.dark li.menu-item>a:hover {
    background: white;
    color: #264c99;
    box-shadow: inset 4px 0px 0px 0px #F0B669;
}
.dark .container-menu{
    background: white;
    padding: 0px 10px;
}
/*################
    TABLE
################## */
.dark .bold-header-table h3 {
    color: #000000;
    font-weight: 500;
    margin-left: 32px;
}
.dark .bold-header-table {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}
.dark .rdt_Table {
    border: 1px solid var(--dark-elements-elements-5);
    border-radius: 8px;
    overflow: hidden;
}
.dark .rdt_TableHead, .dark .rdt_TableHeadRow{
    background-color: var(--dark-background-background-1) !important;
}
.dark .rdt_TableBody > div{
    border:0px;
}
.dark .rdt_TableBody > div:nth-child(odd) {
    background: var(--dark-background-background-2);
}
.dark nav.rdt_Pagination {
    background: transparent;
    border:0px;
}